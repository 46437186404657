import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

// Before using translations, check what is the parameter in the URL
i18next.init(() => {
  if (window.location.pathname === '/')
    return (window.location.pathname = i18next.language || navigator.language);
  // If there is no parameter in the URL use what has been written in i18next.langauge, if nothing has been written then use the language of the browser
  const pathLang = window.location.pathname.split('/')[1];
  const url = window.location.pathname.split('/');
  const langMap = {
    es: '/hazteoir',
    en: '/en-us',
    pt: '/pt-pt',
    fr: "/fr-fr",
  };
if(url.length === 2) {
  if (window.location.pathname === '/en')
    return (window.location.pathname = 'en-row');
  else if (window.location.pathname === '/es')
    return (window.location.pathname = 'hazteoir');
  else if (window.location.pathname === '/pt')
    return (window.location.pathname = 'pt-pt');
  else if (window.location.pathname === '/fr')
    return (window.location.pathname = 'fr-fr');
  else if (!i18next.options.supportedLngs.includes(pathLang)) {
    window.location.href = `${langMap[pathLang]}${window.location.search}` || '/en-row/notfound';
  }
}
});

i18next
  // initialize i18next
  .use(initReactI18next)
  // detect user language
  .use(LanguageDetector)
  // load translation using http -> see /public/locales
  .use(HttpApi)
  // pass the i18n instance to react-i18next
  .init({
    supportedLngs: [
      'pl',
      'hazteoir',
      'es-mx',
      'fr-fr',
      'fr-ca',
      'hr',
      'it',
      'en-us',
      'en-af',
      'en-ca',
      'en-au',
      'en-row',
      'en-gb',
      'en-ie',
      'es-lat',
      'es-ar',
      'hu',
      'nl',
      'ph',
      'pt-br',
      'pt-pt',
      'sk',
      'de',
      'de-at',
      'de-ch',
      'es',
      'pt',
      'en',
      'es-us',
      'fr'
    ],
    nonExplicitSupportedLngs: false,
    cleanCode: true,
    fallbackLng: 'en',
    lowerCaseLng: true,
    detection: {
      // order and from where user language should be detected
      order: [
        'path',
        'cookie',
        'navigator',
        'localStorage',
        'subdomain',
        'htmlTag',
      ],
      // keys or params to lookup language from
      caches: ['cookie'],
    },
    // dir to load translation files
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
    react: { useSuspense: false },
    initImmediate: false,
    debug: false,
  });

export default i18next;
